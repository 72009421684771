// import { useSelector } from "react-redux";
// import serviceUnavailable from "../../../../assets/images/service_unavailable.png";
// import {
//   CHAT_HEAD_TITLE_FONT_COLOR,
//   CHAT_HEAD_STATUS_FONT_COLOR,
//   CHAT_HEAD_STATUS_FONT_SIZE,
//   CHAT_HEAD_STATUS_FONT_WEIGHT,
//   CHAT_HEAD_STATUS_LINE_HEIGHT,
//   CHAT_HEAD_TITLE_FONT_SIZE,
//   CHAT_HEAD_TITLE_FONT_WEIGHT,
//   CHAT_HEAD_TITLE_LINE_HEIGHT,
// } from "../../../../res/resource";
// import {
//   getQuesLoadingStatus,
//   getSearchLoadingStatus,
// } from "../../../../util/helper";
// import { CloseIco } from "../../../common/actionButton/closeIco/CloseIco";
// import "./styles.css";

// const ChatHead = ({ toggleCloseDialogBox }) => {
//   const ques_loading = useSelector(getQuesLoadingStatus);
//   const search_loading = useSelector(getSearchLoadingStatus);
//   return (
//     <div className="chatHead">
//       <img
//         className="chatHead_img"
//         src={serviceUnavailable}
//         alt="Chat Char Icon"
//       />
//       <div className="chatHead_title">
//         <p
//           className="chatHead_title_p"
//           style={{
//             color: CHAT_HEAD_TITLE_FONT_COLOR,
//             fontSize: CHAT_HEAD_TITLE_FONT_SIZE,
//             lineHeight: CHAT_HEAD_TITLE_LINE_HEIGHT,
//             fontWeight: CHAT_HEAD_TITLE_FONT_WEIGHT,
//           }}
//         >{`Sanjeevani`}</p>
//         <p
//           className={`chatHead_title_status ${
//             ques_loading || search_loading
//               ? "chatHead_Italics"
//               : "chatHead_Normal"
//           }`}
//           style={{
//             color: CHAT_HEAD_STATUS_FONT_COLOR,
//             fontSize: CHAT_HEAD_STATUS_FONT_SIZE,
//             lineHeight: CHAT_HEAD_STATUS_LINE_HEIGHT,
//             fontWeight: CHAT_HEAD_STATUS_FONT_WEIGHT,
//           }}
//         >
//           {ques_loading || search_loading ? "Typing..." : "Online"}
//         </p>
//       </div>
//       <CloseIco onClick={toggleCloseDialogBox} />
//     </div>
//   );
// };

// export { ChatHead };
import React from "react";
import indianG from "../../../../assets/images/indian-govern.svg";
import g20 from "../../../../assets/images/g-20.svg";
import "./styles.css";

function ChatHead() {
  return (
    <div className="chatHead">
      {/* <div className="chatHead_icn"> */}
        <img className="g20_img w-full sm:w-auto" src={g20} alt="g-20" />
        <img
          className="gov_img w-full sm:w-auto"
          src={indianG}
          alt="indian government"
        />
      {/* </div> */}
    </div>
  );
}

export default ChatHead;

