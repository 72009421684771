import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import save_icn from "../../../../../assets/images/save_icn.png";
import upload from "../../../../../assets/images/upload.png";
import camera from "../../../../../assets/images/camera.png";
import camera_icon from "../../../../../assets/images/camera_icon.png";
import upload_icon from "../../../../../assets/images/upload_icon.png";
import { ThumbnailPreview } from "../../../../common/thumbnailPreview/ThumbnailPreview";
import { ActionBtn } from "../../../../common/actionButton/actionBtn/ActionBtn";
import { CameraWrap } from "../../../../common/cameraWrap/CameraWrap";
import {
  ADD_TO_THE_LIST,
  BROWSE_PROMPT,
  CAMERA_PROMPT,
  CAPTURE_IMAGE,
  FILE_MAX_COUNT,
  FILE_MAX_SIZE,
  FILE_TYPE_SUPPORT,
  FILE_UPLOAD_COUNT_INFO_PROMPT,
  FILE_UPLOAD_MODEL_DESC_PROMPT,
  FILE_UPLOAD_MODEL_TITLE_PROMPT,
  FILE_UPLOAD_SIZE_INFO_PROMPT,
  FILE_UPLOAD_TYPE_INFO_PROMPT,
  OTHERS_TXT_PLACEHOLDER,
  SAVE_PROMPT,
  UPLOAD_IMAGE,
} from "../../../../../util/const";
import {
  getLangOptFromState,
  getTextFrmPrompt,
  useQuery,
} from "../../../../../util/helper";
import FreeText from "../../textInput/freeText/FreeText";
import filesUploadModel_cancel from "../../../../../assets/images/closeFormDialog_cancel.png";
import "./styles.css";

const FilesUploadModel = (props) => {
  const {
    isSaveBtnEnable = true,
    toggleFileUploadModel,
    // minFilePerOpt = 0,
    quesId,
    curItm,
    togglePreview,
  } = props;

  const query = useQuery();
  const filesInpRef = useRef(null);
  const langOpt = useSelector(getLangOptFromState);
  const [files, setFiles] = useState(curItm?.prompt?.files); //[{text:"",file:}]
  const [highlightInfo, setHighlightInfo] = useState(false);
  const [isCameraOn, setCameraOn] = useState(false);
  const [othersTxt, setOthersText] = useState(
    curItm?.raw_user_resp ? curItm?.raw_user_resp : ""
  );
  const clientDevice = query.get("device");

  const modifyOthersText = (inptVal) => {
    if (inptVal?.length) {
      setOthersText(inptVal[0]?.text);
    } else {
      setOthersText("");
    }
  };

  const toggleCamera = () => {
    setCameraOn((prevCameraState) => !prevCameraState);
  };

  useEffect(() => {
    const dt = new DataTransfer();
    files?.forEach((fileItm) => {
      if (fileItm.file) {
        dt.items.add(fileItm.file);
      }
    });
    filesInpRef.current.files = dt.files;
  }, [files]);

  useEffect(() => {
    if (highlightInfo) {
      setTimeout(() => {
        setHighlightInfo(false);
      }, 5000);
    }
  }, [highlightInfo]);

  const handleClose = () => {
    toggleFileUploadModel(files, true, othersTxt);
  };

  const removeFile = (inpFileTitle) => {
    const newFiles = files?.filter(
      (fileItm, idx) =>
        `${getTextFrmPrompt(curItm.prompt.text, langOpt.value)}${
          curItm.value === ADD_TO_THE_LIST
            ? othersTxt
              ? " - " + othersTxt
              : ""
            : ""
        }_${idx + 1}` !== inpFileTitle
    );
    setFiles(newFiles);
  };

  const isFileValid = (file) => {
    const nameParts = file.name.split(".");
    const fileExt = nameParts[nameParts.length - 1];
    const fileType = file.type.split("/")[0];
    const _fileSize = file.size / 1000;
    if (
      _fileSize <= FILE_MAX_SIZE &&
      (FILE_TYPE_SUPPORT.includes(`.${fileExt}`) ||
        FILE_TYPE_SUPPORT.includes(`${fileType}/*`))
    ) {
      return true;
    } else {
      setHighlightInfo(true);
      return false;
    }
  };

  const handleChange = (inpFiles) => {
    let newInpFiles = inpFiles
      .filter((filteItm) => isFileValid(filteItm))
      .map(
        (fileItm) =>
          new File(
            [fileItm],
            `${Date.now()}_${quesId}_${curItm.value}_${fileItm.name?.replaceAll(
              "_",
              ""
            )}`
          )
      );
    const newFiles = files?.length
      ? files?.filter((filesItm) =>
          newInpFiles.find(
            (newInpFilesItm) => newInpFilesItm.name === filesItm.name
          )
            ? false
            : true
        )
      : [];
    if (FILE_MAX_COUNT < newInpFiles?.length + newFiles?.length) {
      setHighlightInfo(true);
    }
    newInpFiles = newInpFiles.slice(0, FILE_MAX_COUNT - newFiles?.length);
    setFiles([...newFiles, ...newInpFiles]);
  };

  const getFilesPreview = () => (
    <div
      className={`filesUploadModel_preview_container ${
        files?.length ? "filesUploadModel_second" : "preview_empty"
      }`}
    >
      {files?.length === 0
        ? ""
        : files?.map((itm, idx) => (
            <ThumbnailPreview
              {...{
                itm,
                removeFile,
                title: `${getTextFrmPrompt(curItm.prompt.text, langOpt.value)}${
                  curItm.value === ADD_TO_THE_LIST
                    ? othersTxt
                      ? " - " + othersTxt
                      : ""
                    : ""
                }_${idx + 1}`,
                key: itm.name,
                togglePreview,
              }}
            />
          ))}
    </div>
  );
  return (
    <div className="filesUploadModel">
      <img
        className="filesUploadModel_cancel"
        src={filesUploadModel_cancel}
        alt={"filesUploadModel_cancel"}
        onClick={() =>
          toggleFileUploadModel(
            curItm?.prompt?.files,
            false,
            curItm?.raw_user_resp
          )
        }
      />
      <div className="filesUploadModelTitle">
        <h3>
          {`${getTextFrmPrompt(
            FILE_UPLOAD_MODEL_TITLE_PROMPT,
            langOpt.value
          )} `}
          <span className="filesUploadModelTitleFocus">
            {`${getTextFrmPrompt(curItm.prompt.text, langOpt.value)} ${
              curItm.value === ADD_TO_THE_LIST
                ? othersTxt
                  ? " - " + othersTxt
                  : ""
                : ""
            }`}
          </span>
        </h3>
        <h4>{`${getTextFrmPrompt(
          FILE_UPLOAD_MODEL_DESC_PROMPT,
          langOpt.value
        )} `}</h4>
      </div>
      <div className="filesUploadModelContainer">
        <div
          className="filesUploadModel_first"
          onDrop={(e) => {
            e.preventDefault();
            handleChange(Array.from(e.dataTransfer.files));
          }}
        >
          <div className="filesUploadModel-camera-browse-container">
            <div className="filesUploadModel_action_block filesUploadModel_action_block_first">
              <img
                src={upload_icon}
                alt="upload_icon"
                className="action_block_icon"
              />
              <h4>{getTextFrmPrompt(UPLOAD_IMAGE, langOpt.value)}</h4>
              <input
                ref={filesInpRef}
                className="filesUploadModel_input"
                id="filesUploadModel_input_div"
                type="file"
                name="uploadfile"
                multiple
                accept={FILE_TYPE_SUPPORT.toString()}
                onChange={(e) => handleChange(Array.from(e.target.files))}
              />
              <label htmlFor="filesUploadModel_input_div">
                <ActionBtn
                  {...{
                    isCurQuesUpdt: true,
                    onClick: () => {},
                    respArr: [],
                    btnPrompt: BROWSE_PROMPT,
                    actionBtn_icn: upload,
                  }}
                />
              </label>
            </div>
            {(!clientDevice || clientDevice !== "mobile") && (
              <div className="filesUploadModel_action_block">
                <img
                  src={camera_icon}
                  alt="camera_icon"
                  className="action_block_icon"
                />
                <h4>{getTextFrmPrompt(CAPTURE_IMAGE, langOpt.value)}</h4>
                <ActionBtn
                  {...{
                    isCurQuesUpdt: true,
                    onClick: toggleCamera,
                    respArr: [],
                    btnPrompt: CAMERA_PROMPT,
                    actionBtn_icn: camera,
                  }}
                />
              </div>
            )}
          </div>
          <h5
            className={`filesUploadModel-info-text ${
              highlightInfo ? "filesUploadModel-info-highlight" : ""
            }`}
          >
            {getTextFrmPrompt(FILE_UPLOAD_TYPE_INFO_PROMPT, langOpt.value)}
            <br />
            {getTextFrmPrompt(FILE_UPLOAD_SIZE_INFO_PROMPT, langOpt.value)}
            <br />
            {getTextFrmPrompt(FILE_UPLOAD_COUNT_INFO_PROMPT, langOpt.value)}
          </h5>
        </div>
        <div>{getFilesPreview()}</div>
      </div>
      <div className="filesUploadModel_footer">
        {curItm.value === ADD_TO_THE_LIST ? (
          <FreeText
            {...{
              isSkippable: true,
              placeholder: OTHERS_TXT_PLACEHOLDER,
              onSave: () => {},
              onUpdt: modifyOthersText,
              saved: othersTxt,
              isSaveBtnEnable: false,
              highlightIfEmpty: true,
            }}
          />
        ) : (
          <div></div>
        )}
        {isSaveBtnEnable && (
          <ActionBtn
            {...{
              isCurQuesUpdt:
                files?.length > 0 &&
                (curItm.value !== ADD_TO_THE_LIST || othersTxt)
                  ? true
                  : false,
              onClick: handleClose,
              respArr: files,
              btnPrompt: SAVE_PROMPT,
              actionBtn_icn: save_icn,
            }}
          />
        )}
      </div>

      {isCameraOn && (
        <CameraWrap
          {...{
            handleChange,
            toggleCamera,
            toggleFileUploadModel,
            togglePreview,
            removeFile,
          }}
        />
      )}
    </div>
  );
};

export default FilesUploadModel;
