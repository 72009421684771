export const chatCred = {
  q_set_id: "version-1",
};

export const ADD_TO_THE_LIST = "ADD_TO_THE_LIST";
export const NOT_APPLICABLE = "NOT_APPLICABLE";
export const PRE_DOMINANT_SYMP_QUES_VAL = "111";
export const AUTOCOMPLETE_SYMP_QUES_VAL = "112";
export const ASSC_SYMP_TAG = "30";

export const NAME_PLACE_HOLDER = {
  1: "Anita",
  2: "अनीता",
};

export const FILE_TYPE_SUPPORT = [
  // ".xlsx",
  // ".xls",
  // ".csv",
  "image/*",
  // ".doc",
  // ".docx",
  // ".txt",
  // ".pdf",
]; //check for diacom ext support
export const FILE_MAX_COUNT = 3;
export const FILE_MAX_SIZE = 3000; //~3MB

export const DEF_LANG_OPT = "1";
export const maxVisibleOpts = 8;
export const BACK_PROMPT = "Back";
export const NEXT_PROMPT = "Next";
export const SKIP_PROMPT = "Skip";
export const MCQ_SUBMIT_PROMPT = "I’ve selected my options";
export const QUES_TYPE = {
  TEXT_WORDS: "text-words",
  TEXT_NUMERIC: "text-numeric",
  MULTI_SELECT_CHIPS: "multi-select",
  MULTI_SELECT_CHIPS_FILE_UPLOAD: "multi-select-file-upload",
  MULTI_SELECT_AUTOTEXT_FILE_UPLOAD: "multi-select-autotext-file-upload",
  MULTI_SELECT_AUTOTEXT: "multi-select-autotext",
  MULTI_SELECT_SEARCH: "multi-select-search",
  MULTI_SELECT_TILES: "multi-select-tiles",
  SINGLE_SELECT_CHIPS: "single-select",
  SINGLE_SELECT_CHIPS_FILE_UPLOAD: "single-select-file-upload",
  SINGLE_SELECT_AUTOTEXT_FILE_UPLOAD: "single-select-autotext-file-upload",
  SINGLE_SELECT_TILES: "single-select-tiles",
  SINGLE_SELECT_AUTOTEXT: "single-select-autotext",
};

export const listOfLang = [
  { value: "1", prompt: { text: "English", image: "", type: "free-text" } },
  { value: "2", prompt: { text: "हिंदी", image: "", type: "free-text" } },
  { value: "3", prompt: { text: "தமிழ்", image: "", type: "free-text" } },
  { value: "4", prompt: { text: "తెలుగు", image: "", type: "free-text" } },
  { value: "5", prompt: { text: "മലയാളം", image: "", type: "free-text" } },
  { value: "6", prompt: { text: "ಕನ್ನಡ", image: "", type: "free-text" } },
  { value: "7", prompt: { text: "मराठी", image: "", type: "free-text" } },
  { value: "8", prompt: { text: "ଓଡିଆ", image: "", type: "free-text" } },
  { value: "9", prompt: { text: "ਪੰਜਾਬੀ", image: "", type: "free-text" } },
  { value: "10", prompt: { text: "ગુજરાતી", image: "", type: "free-text" } },
  { value: "11", prompt: { text: "অসমীয়া", image: "", type: "free-text" } },
  { value: "12", prompt: { text: "বাংলা", image: "", type: "free-text" } },
  { value: "13", prompt: { text: "اردو", image: "", type: "free-text" } },
];

export const sanjeevani_placeholder = {
  1: "Sanjeevani",
  2: "संजीवनी",
  3: "சஞ்சீவனி",
  4: "సంజీవని",
  5: "സഞ്ജീവനി",
  6: "ಸಂಜೀವನಿ",
  7: "संजीवनी",
  8: "ସଞ୍ଜୀବନୀ",
  9: "ਸੰਜੀਵਨੀ",
  10: "સંજીવની",
  11: "সঞ্জীৱনী",
  12: "সঞ্জীবনী",
  13: "سنجیوانی",
};

export const online_placeholder = {
  1: "Online",
  2: "ऑनलाइन",
  3: "நிகழ்நிலை	",
  4: "ఆన్‌లైన్",
  5: "ഓൺലൈൻ",
  6: "ಆನ್ಲೈನ್	",
  7: "ऑनलाइन",
  8: "ଅନଲାଇନ୍",
  9: "ਔਨਲਾਈਨ",
  10: "ઓનલાઇન",
  11: "অনলাইন",
  12: "অনলাইন",
  13: "آن لائن",
};

export const SAVE_PROMPT = {
  1: "Save & Next",
  2: "सहेजें और अगला",
  3: "சேமிக்க மற்றும் அடுத்து",
  4: "సేవ్ & తదుపరి",
  5: "സേവ് & അടുത്തത്",
  6: "ಉಳಿಸಿ & ಮುಂದುವರಿಸಿ",
  7: "संचयन करा आणि पुढील",
  8: "ରକ୍ଷିତ କରନ୍ତୁ ଏବଂ ପରେଣି",
  9: "ਸੰਭਾਲੋ ਅਤੇ ਅੱਗੇ ਜਾਓ",
  10: "સાચવો અને આગળ વધો",
  11: "সংৰক্ষণ কৰক আৰু পৰৱৰ্তী",
  12: "সংরক্ষণ করুন এবং পরবর্তী",
  13: "محفوظ کریں اور آگے بڑھیں",
};

export const BROWSE_PROMPT = {
  1: "Browse",
  2: "ब्राउज़ करें",
  3: "உலவ",
  4: "బ్రౌస్",
  5: "ബ്രൗസ്",
  6: "ಬ್ರೌಸ್",
  7: "ब्राउझ",
  8: "ବ୍ରାଉଜ",
  9: "ਬ੍ਰਾਊਜ਼ ਕਰੋ",
  10: "બ્રાઉઝ કરો",
  11: "ব্ৰাউচ",
  12: "ব্রাউজ",
  13: "براؤز کریں",
};

export const CAMERA_PROMPT = {
  1: "Camera",
  2: "कैमरा",
  3: "கேமரா",
  4: "కెమెరా",
  5: "ക്യാമറ",
  6: "ಕ್ಯಾಮೆರಾ",
  7: "कॅमेरा",
  8: "କ୍ୟାମେରା",
  9: "ਕੈਮਰਾ",
  10: "કૅમેરા",
  11: "কেমেৰা",
  12: "ক্যামেরা",
  13: "کیمرا",
};
export const UPLOAD_IMAGE = {
  1: "Upload image",
  2: "छवि अपलोड करें",
  3: "படத்தை பதிவேற்ற",
  4: "చిత్రం అప్లోడ్ చేయండి",
  5: "ചിത്രം അപ്ലോഡ് ചെയ്യുക",
  6: "ಚಿತ್ರವನ್ನು ಸೇರಿಸಿ \r\n",
  7: "चित्र अपलोड करा",
  8: "ଛବି ଆପଲୋଡ୍ କରନ୍ତୁ",
  9: "ਚਿੱਤਰ ਅੱਪਲੋਡ ਕਰੋ",
  10: "છબી અપલોડ કરો",
  11: "ছবি আপলোড কৰক",
  12: "ছবি আপলোড করুন",
  13: "تصویر اپلوڈ کریں",
};

export const CAPTURE_IMAGE = {
  1: "Capture image",
  2: "छवि कैप्चर करें",
  3: " படம்பிடிக்க",
  4: "చిత్రం తీసుకొనండి",
  5: "ചിത്രം എടുക്കുക",
  6: "ಚಿತ್ರವನ್ನು ತೆಗೆಯಿರಿ",
  7: "चित्र कॅप्चर करा/ चित्र काढा ",
  8: "ଛବି କ୍ୟାପ୍ଚର୍ କରନ୍ତୁ",
  9: "ਚਿੱਤਰ ਕੈਪਚਰ ਕਰੋ",
  10: "છબી કૅપ્ચર કરો",
  11: "ছবি কেপচাৰ কৰক",
  12: "ছবি ক্যাপচার করুন",
  13: "تصویر کیپچر کریں",
};

export const OR_PROMPT = {
  1: "OR",
  2: "या",
  3: "அல்லது",
  4: "లేదా",
  5: "അല്ലെങ്കിൽ ",
  6: "ಅಥವಾ",
  7: "किंवा",
  8: "ଅଥ୍ୟବା",
  9: "ਜਾਂ",
  10: "અથવા",
  11: "অথৱা",
  12: "অথবা",
  13: "یا",
};

export const INP_PLACEHOLDER = {
  1: "Type here for search",
  2: "खोज के लिए यहाँ टाइप करें",
  3: "தேட இங்கே டைப் செய்யவும்",
  4: "ఇక్కడ శోధించండి",
  5: "തിരയുന്നതിനായി ഇവിടെ ടൈപ്പ് ചെയ്യുക. ",
  6: "ಇಲ್ಲಿ ಹುಡುಕಿ",
  7: "शोधासाठी येथे टाइप करा",
  8: "ଖୋଜନ୍ତୁ",
  9: "ਇਥੋਂ ਖੋਜੋ",
  10: "શોધ કરવા માટે અહીં ટાઇપ કરો",
  11: "সন্ধানৰ বাবে ইয়াত টাইপ কৰক",
  12: "এখানে খোঁজ করুন",
  13: "تلاش کرنے کے ليے یہاں ٹائپ کریں",
};

export const FORM_ERR_PROMPT_HEAD = {
  1: "Something went wrong. Kindly reload the page.",
  2: "कुछ गड़बड़ हो गई है। कृपया पृष्ठ फिर से लोड करें।",
  3: "ஏதோ தவறு நடந்துவிட்டது. தயவு செய்து திரும்ப லோட் செய்யவும்",
  4: "ఎక్కడో తేడ జరిగింది. దయచేసి పేజీని మళ్లీ లోడ్ చేయండి.",
  5: "എന്തോ കുഴപ്പം സംഭവിച്ചു . ദയവ്ചെയ്ത് പേജ് വീണ്ടും ലോഡ് ചെയ്യുക.",
  6: "ಏನೋ ತಪ್ಪಾಗಿದೆ. ದಯವಿಟ್ಟು ಪುಟವನ್ನು ಪುನಃ ಲೋಡ್ ಮಾಡಿ.",
  7: "काहीतरी चुकलंय. कृपया पृष्ठ पुन्हा लोड करा.",
  8: "କୌଣସି ଭୁଲଗଲା। ଦୟାକରି ପୃଷ୍ଠଟି ପୁନର୍ଲୋଡ୍ କରନ୍ତୁ।",
  9: "ਕੁਝ ਗਲਤ ਹੋ ਗਿਆ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ ਪੇਜ ਨੂੰ ਮੁੜ ਲੋਡ ਕਰੋ।",
  10: "કંઈક ખોટું થયું છે. કૃપા કરીને પેજને ફરીથી લોડ કરો.",
  11: "কোনো সমস্যা ঘটিছে। অনুগ্ৰহ কৰি পৃষ্ঠাটো পুনৰ লোড কৰক।",
  12: "কিছু ভুল হয়েছে। দয়া করে পৃষ্ঠাটি আবার লোড করুন।",
  13: "کچھ غلط ہو گیا۔ براہ کرم صفحہ دوبارہ لوڈ کریں۔",
};

export const FORM_ERR_PROMPT_DESC = {
  1: "Kindly contact the administrator, if the issue persists.",
  2: "अगर समस्या बरकरार है, तो कृपया प्रशासक से संपर्क करें।",
  3: "இந்த பிரச்சனை தொடர்ந்தால், அட்மினை தொடர்பு கொள்ளவும்.",
  4: "సమస్య కొనసాగితే దయచేసి నిర్వాహకుడిని సంప్రదించండి.",
  5: "പ്രശ്നം വീണ്ടും തുടരുന്നുണ്ടങ്കിൽ ദയവ്ചയത് അഡ്മിനിസ്ട്രേറ്ററുമായി ബന്ധപെടുക ",
  6: "ಸಮಸ್ಯೆ ಮುಂದುವರೆಯುತ್ತಿದ್ದರೆ, ದಯವಿಟ್ಟು ನಿರ್ವಾಹಕರನ್ನು ಸಂಪರ್ಕಿಸಿ.",
  7: "समस्या असल्यास, कृपया प्रशासकाशी संपर्क साधा.",
  8: "ଯଦି ସମସ୍ୟା ଅନ୍ଧାରୁ, ଦୟାକରି ପ୍ରଶାସକଙ୍କୁ ସଂପର୍କ୍ତା କରନ୍ତୁ।",
  9: "ਜੇ ਸਮੱਸਿਆ ਬਣੀ ਰਹੇ, ਤਾਂ ਕਿਰਪਾ ਕਰਕੇ ਪ੍ਰਬੰਧਕ ਨੂੰ ਸੰਪਰਕ ਕਰੋ।",
  10: "જો સમસ્યા ચાલુ રહે તો કૃપા કરીને ઍડમિનિસ્ટ્રેટરનો સંપર્ક કરો.",
  11: "অনুগ্ৰহ কৰি প্ৰশাসকৰ সৈতে যোগাযোগ কৰক, যদি সমস্যাটো অব্যাহত থাকে।",
  12: "সমস্যা থাকলে, সমস্যা থাকলে পরিচালকের সাথে যোগাযোগ করুন।",
  13: "اگر کوئی مسئلہ پیش آرہا ہے تو براہ کرم منتظم سے رابطہ کریں۔",
};

export const RESET_FORM_DIALOG_MSSG = {
  1: "Are you sure, you want to delete all the responses given to the questions so far?",
  2: "क्या आप निश्चित हैं कि आप अब तक दिए गए सभी जवाबों को हटाना चाहते हैं?",
  3: "இந்த கேள்விக்கு நீங்கள் இதுவரை அளித்த அனைத்து பதில்களையும் கண்டிப்பாக அழிக்க விரும்புகீர்களா?",
  4: "మీరు ఖచ్చితంగా, ఇప్పటివరకు ప్రశ్నలకు ఇచ్చిన అన్ని ప్రతిస్పందనలను తొలగించాలనుకుంటున్నారా?",
  5: "നിങ്ങൾക് ഉറപ്പാണോ, നിങ്ങൾ ഇപ്പോൾ വരെ നൽകിയ എല്ലാ പ്രതികരണങ്ങളും ഡിലീറ്റ് ചെയ്യണോ?",
  6: "ನೀವು ಹಿಂದೆ ಕೊಟ್ಟ ಎಲ್ಲಾ ಉತ್ತರಗಳನ್ನು ಅಳಿಸ ಬಯಸುವಿರಾ?",
  7: "तुमची खात्री आहे का , तुम्ही आतापर्यंत प्रश्नांना दिलेले सर्व प्रतिसाद हटवू इच्छिता??",
  8: "ଆପଣ ଯଦି ଏଥିରେ ଯେତେବେଳେ ପ୍ରଶ୍ନଙ୍କୁ ପ୍ରଦାନ କରିଯାଇଛନ୍ତି ସେବାହେଁ?",
  9: "ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਹੋ ਕਿ ਤੁਸੀਂ ਹਿੱਥੋਂ ਹੀ ਦਿੱਤੇ ਗਏ ਸਵਾਲਾਂ ਦੇ ਜਵਾਬ ਨੂੰ ਹਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  10: "શું તમને ખાતરી છે કે, તમે અત્યાર સુધી આપેલા તમામ પ્રશ્નોના જવાબો કાઢી નાખવા માંગો છો?",
  11: "আপুনি নিশ্চিতনে, আপুনি এতিয়ালৈকে প্ৰশ্নবোৰত দিয়া সকলো সঁহাৰি বিলোপ কৰিব বিচাৰে নেকি?",
  12: "আপনি নিশ্চিত কি আপনি এই পর্যালোচনা প্রদান করা সমস্ত উত্তর মুছতে চান?",
  13: "کیا آپ پُر یقین ہیں، کہ آپ اب تک کے سوالات کے جوابات کو حذف کرنا چاہتے ہیں؟",
};

export const CLEAR_RESPONSE_TITLE_DIALOG_MSSG = {
  1: "Do you want to remove {} as an option?",
  2: "क्या आप {} को एक विकल्प के रूप में हटाना चाहते हैं?",
  3: " {} என்ற ஆப்ஷனை நீங்கள் அழிக்க விரும்புகிறீர்களா ",
  4: "మీరు {}ని ఎంపికగా తీసివేయాలనుకుంటున్నారా?",
  5: "നിങ്ങൾക് ഈ രേഖപെടുത്തിയിരിക്കയുന്ന {} ഓപ്ഷൻ കളയണോ ?",
  6: "ಒಂದು {} ಆಯ್ಕೆಯನ್ನು ತೆಗೆದುಕೊಳ್ಳಬೇಕೆಂದು ನೀವು ಬಯಸುವಿರಾ?",
  7: "तुम्हाला पर्याय म्हणून {} काढायचा आहे  का ?\r\n",
  8: "{} ଏକ ବିକଳ୍ପ ହେଉଛି କି ନାଁ?",
  9: "ਕੀ ਤੁਸੀਂ {} ਨੂੰ ਇਕ ਚੋਣ ਤੌਰ ਤੇ ਹਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  10: "શું તમે {} ને વિકલ્પ તરીકે દૂર કરવા માંગો છો?",
  11: "আপুনি {} ক এটা বিকল্প হিচাপে আঁতৰাব বিচাৰে নেকি?",
  12: "{} একটি বিকল্প হিসেবে সরাতে চান?",
  13: "کیا آپ ایک اختیار کے طور پر ہٹانا {} چاہتے ہیں؟",
};

export const CLEAR_RESPONSE_BODY_DIALOG_MSSG = {
  1: "This will also remove any pictures uploaded for this body part",
  2: "इससे इस शरीर भाग के लिए अपलोड की गई किसी भी चित्रों को भी हटा देगा।",
  3: "இது இந்த உடல் பகுதிக்கு பதிவேற்றப்பட்ட படங்களையும் அகற்றும்",
  4: "ఇది ఈ భాగం కోసం అప్\u200cలోడ్ చేసిన ఏవైనా చిత్రాలను కూడా తీసివేస్తుంది",
  5: "ഇത് ഈ ശരീര ഭാഗത്തിന് അപ്ലോഡ് ചെയ്ത ഏതേലും ചിത്രങ്ങൾ ഉണ്ടെങ്കിൽ അതും നഷ്ടപ്പെടും ",
  6: "ಇದು ಈ ದೇಹ ಭಾಗಕ್ಕೆ ಸಂಬಂಧಿಸಿದ ಯಾವುದೇ ಚಿತ್ರಗಳನ್ನೂ ಅಳಿಸುವುದು",
  7: "हे शरीराच्या या भागासाठी अपलोड केलेली कोणतीही चित्रे देखील काढून टाकेल",
  8: "ଏହାଣ୍ଟିକୁ ଅପଲୋଡ୍ କରାଯାଇଥାଏ ସେହି ଶରୀର ଅଂଶର ଯାଉଛି ପକଡାଇବା ଗଲାଇଥାଏ।",
  9: "ਇਸ ਨਾਲ ਇਸ ਸਰੀਰ ਦੇ ਭਾਗ ਲਈ ਅੱਪਲੋਡ ਕੀਤੇ ਗਏ ਕੋਈ ਵੀ ਤਸਵੀਰਾਂ ਨੂੰ ਵੀ ਹਟਾਉਣਾ ਹੋਵੇਗਾ",
  10: "આ આપના ભાગ માટે અપલોડ કરેલા કોઈપણ છબીને પણ દૂર કરશે",
  11: "ই এই শৰীৰৰ অংশটোৰ বাবে আপলোড কৰা যিকোনো ছবিও আঁতৰ কৰিব",
  12: "এটি এই শারীরিক অংশে আপলোড করা সমস্ত চিত্রগুলি সহ যেকোনো ছবি সরাতে সরাতে",
  13: "اس سے اس جسم کے حصے کے ليے اپلوڈ کردی گئی کوئی تصویر بھی ہٹا دے گی",
};

export const RESET_PROMPT = {
  1: "Reset",
  2: "रीसेट करें",
  3: "மீட்டமைக்க",
  4: "రీసెట్",
  5: "റിസെറ്റ്",
  6: "ಮರುಹೊಂದಿಸಿ",
  7: "रीसेट करा",
  8: "ରିସେଟ୍",
  9: "ਰੀਸੈਟ ਕਰੋ",
  10: "રીસેટ કરો",
  11: "ৰিছেট কৰক",
  12: "রিসেট করুন",
  13: "ری سیٹ کریں",
};

export const REMOVE_CONFIRM_PROMPT = {
  1: "Yes, remove",
  2: "हां, हटाएं",
  3: "ஆம், அகற்றுக",
  4: "అవును, తొలగించు",
  5: "അതെ, നീക്കു",
  6: "ಹೌದು, ಅಳಿಸು",
  7: "होय, हटवा",
  8: "ହୌଣି, କାଢ଼ି",
  9: "ਹਾਂ, ਹਟਾਓ",
  10: "હા, દૂર કરો",
  11: "হয়, আঁতৰাওক",
  12: "হ্যাঁ, সরান",
  13: "جی ہاں، ہٹائیں",
};

export const OTHERS_TXT_PLACEHOLDER = {
  1: "If others selected, please explain",
  2: "अगर अन्य चुने गए हैं, तो कृपया स्पष्ट करें",
  3: "பிறர் தேர்ந்தெடுத்தால், அதை விளக்கவும்",
  4: "ఇతరులు ఎంచుకోనున్నారా, దయచేసి వివరించండి",
  5: "മറ്റുള്ളവ തിരഞ്ഞെടുത്തെങ്കൽ , ദയവ്ചയത് വിശദമാക്കുക",
  6: "ಇತರರು ಆಯ್ಕೆ ಮಾಡಿದ್ದಾರಾ, ದಯವಿಟ್ಟು ವಿವರಿಸಿ",
  7: "इतर निवडल्यास, कृपया स्पष्टपणे सांगा",
  8: "ଯଦି ଅନ୍ୟାନ୍ୟ ଚୟନ କରାଯାଇଛନ୍ତି, ଦୟାକରି ବିଶେଷଜନାଂକ କହିବା।",
  9: "ਜੇ ਹੋਰਾਂ ਨੇ ਚੋਣ ਕੀਤੀ ਹੋਵੇ, ਤਾਂ ਕਿਰਪਾ ਕਰਕੇ ਵੀ ਸੰਪ੍ਰਦਾਨ ਕਰੋ",
  10: 'જો વિકલ્પ તરીકે "અન્ય" પસંદ કરવામાં આવ્યું હોય, તો કૃપા કરીને સમજાવો',
  11: "যদি অন্যচয় কৰা হয়, অনুগ্ৰহ কৰি ব্যাখ্যা কৰক",
  12: "অন্যের যদি চয়ন করা হয়েছে, তবে বিস্তারিত দিন",
  13: "اگر دوسروں نے منتخب کیا ہے تو براہ کرم وضاحت فراہم کریں",
};

export const CANCEL_PROMPT = {
  1: "Cancel",
  2: "रद्द करें",
  3: "ரத்துசெய்ய",
  4: "రద్దు చేయండి",
  5: "റദ്ദ് ചെയ്യുക",
  6: "ರದ್ದು ಮಾಡಿ",
  7: "रद्द करा",
  8: "ରଦ୍ଦ କରନ୍ତୁ",
  9: "ਰੱਦ ਕਰੋ",
  10: "રદ કરો",
  11: "বাতিল কৰা",
  12: "বাতিল করুন",
  13: "منسوخ کریں",
};

export const FILE_UPLOAD_TYPE_INFO_PROMPT = {
  1: "Supported file extensions: jpg, png, jpeg, webp",
  2: "समर्थित फ़ाइल एक्सटेंशन: jpg, png, jpeg, webp",
  3: "ஆதரிக்கப்படுகின்ற கோப்பு வசதிகள்: jpg, png, jpeg, webp",
  4: "మద్దతు చేస్తున్న ఫైలు పొడవులు: jpg, png, jpeg, webp",
  5: "സപ്പോർട്ട് ചെയുന്ന ഫയൽ എക്സ്റ്റൻഷൻസ് : jpg, png, jpeg, webp",
  6: "ಬೆಂಬಲಿಸುವ ಫೈಲ್ ವಿಸ್ತರಣೆಗಳು: jpg, png, jpeg, webp",
  7: "समर्थित फ़ाइल विस्तारणे: jpg, png, jpeg, webp",
  8: "ସମର୍ଥିତ ଫାଇଲ ଏକ୍ସଟେନ୍ସନ୍ସ: jpg, png, jpeg, webp",
  9: "ਸਹਿਯੋਗੀ ਫਾਇਲ ਐਕਸਟੈਂਸ਼ਨ: jpg, png, jpeg, webp",
  10: "આધારિત ફાઇલ એક્સ્ટેન્શન્સ: jpg, png, jpeg, webp",
  11: "সমর্থিত ফাইল এক্সটেনশনসমূহ: jpg, png, jpeg, webp",
  12: "সাপোর্টেড ফাইল এক্সটেনশনসমূহ: jpg, png, jpeg, webp",
  13: "معاونت کردہ فائل کیسے: jpg, png, jpeg, webp",
};

export const FILE_UPLOAD_SIZE_INFO_PROMPT = {
  1: "Image should not be greater than 3 MB",
  2: "छवि 3 MB से अधिक नहीं होनी चाहिए",
  3: "படம் 3 MB-க்கு அதிகமாக இருக்கக்கூடாது",
  4: "చిత్రం 3 MB కంటే ఎక్కడ ఉండకూడదు",
  5: "ചിത്രം 3 MB- യിൽ അധികം ആകരുത് ",
  6: "ಚಿತ್ರ ಹೆಚ್ಚಿನ ಗಾತ್ರದಲ್ಲಿ 3 MB ಆಗಬಾರದು",
  7: "प्रतिमा 3 MB पेक्षा मोठी असू नये",
  8: "ଚିତ୍ର 3 MB ବଡ଼ ନାହିଁ ହେବା ପାଇଁ",
  9: "ਚਿੱਤਰ 3 ਮੀਗਾਬਾਇਟ ਤੋਂ ਵੱਧ ਨਹੀਂ ਹੋਣਾ ਚਾਹੀਦਾ",
  10: "ચિત્ર 3 MB થી મોટું ન હોવું",
  11: "চিত্ৰ 3 MB তৰ বেছি নাই হোৱা উচিত",
  12: "চিত্র 3 এমবি-তের বেশি হতে পারবে না",
  13: "تصویر 3 ایم بی سے زیادہ نہیں ہونی چاہئے",
};

export const FILE_UPLOAD_COUNT_INFO_PROMPT = {
  1: "Maximum 3 images allowed",
  2: "अधिकतम 3 छवियाँ अनुमत हैं।",
  3: "அதிகமாக 3 படங்கள் அனுமதிக்கப்படுகின்றன.",
  4: "అధికముగా 3 చిత్రాలు అనుమతించబడును",
  5: "പരമാവധി 3 ചിത്രങ്ങൾ അനുവദനീയം ആണ് ",
  6: "ಗರಿಷ್ಠ 3 ಚಿತ್ರಗಳನ್ನು ಅನುಮತಿಸಲಾಗಿದೆ",
  7: "जास्तीत जास्त 3 प्रतिमा अनुमती आहे",
  8: "ଅଧିକତମ 3 ଚିତ୍ରର ଅନୁମତି ଦେଇଛି।",
  9: "ਵੱਧ ਤੋਂ ਵੱਧ 3 ਚਿੱਤਰਾਂ ਦੀ ਇਜਾਜ਼ਤ ਹੈ",
  10: "મહત્તમ 3 ચિત્રોની મંજૂરી છે",
  11: "অধিকতম 3 চিত্ৰ অনুমোদন দিৱ আছে",
  12: "সর্বাধিক 3 চিত্র অনুমোদিত",
  13: "3 زیادہ سے زیادہ تصاویر کی اجازت ہے۔",
};

export const FILE_UPLOAD_MODEL_TITLE_PROMPT = {
  1: "Add Picture for",
  2: "चित्र जोड़ें",
  3: "படங்களை சேர்",
  4: "చిత్రాలు జోడించండి",
  5: "ചിത്രങ്ങൾ ചേർക്കുക",
  6: "ಚಿತ್ರಗಳನ್ನು ಸೇರಿಸಿ",
  7: "चित्रे जोडा",
  8: "ଚିତ୍ର ଯୋଗ କର",
  9: "ਚਿੱਤਰ ਜੋੜੋ",
  10: "ચિત્રો ઉમેરો",
  11: "চিত্ৰ যোগ কৰো",
  12: "ছবি যোগ করুন",
  13: "تصاویر شامل کریں",
};

export const FILE_UPLOAD_MODEL_DESC_PROMPT = {
  1: "Where the problem is located",
  2: "प्रभावित शरीर के अंग के लिए",
  3: "பாதிக்கப்பட்ட உடல் பகுதிக்கு",
  4: "ప్రభావిత శరీర భాగం కోసం",
  5: "ബാധിച്ച ശരീരഭാഗത്തിന്",
  6: "ಬಾಧಿತ ದೇಹದ ಭಾಗಕ್ಕೆ",
  7: "शरीराच्या प्रभावित भागासाठी",
  8: "ପ୍ରଭାବିତ ଶରୀରର ଅଂଶ ପାଇଁ |",
  9: "ਪ੍ਰਭਾਵਿਤ ਸਰੀਰ ਦੇ ਹਿੱਸੇ ਲਈ",
  10: "અસરગ્રસ્ત શરીરના ભાગ માટે",
  11: "আক্ৰান্ত শৰীৰৰ অংশৰ বাবে",
  12: "আক্রান্ত শরীরের অংশের জন্য",
  13: "جسم کے متاثرہ حصے کے لیے",
};

export const dispMssg = {
  intro_head: {
    1: "A little about me first. 🙃 ",
    2: "पहले मैं अपने बारे में आपको थोड़ी जानकारी दे दूँ। 🙃 ",
  },
  intro_mssg: {
    1: "I am your Doctor's Assistant and my name is Sanjeevani. My job is to understand your health issues in detail and communicate it to your doctor.",
    2: "मैं आपके डॉक्टर की सहायक हूँ और मेरा नाम संजीवनी है। मेरा काम आपके स्वास्थ्य की समस्याओं को विस्तार से समझना और आपके डॉक्टर को सभी बातें बताना है।",
  },
};

export const PRE_DOMINANT_SYMPS = [
  { value: "55" },
  { value: "72" },
  { value: "92" },
  { value: "7" },
  { value: "88" },
  { value: "87" },
  { value: "283" },
  { value: "96" },
  { value: "99" },
];

// hcv : hard coded value
