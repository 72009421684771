// import { connect } from "react-redux";
// import { useEffect, useState } from "react";
// import { useCallback } from "react";
// import { ChatBody } from "./chatBody/ChatBody";
// import { CloseFormDialog } from "../../fields/closeFormDialog/CloseFormDialog";
// import { sendMessage } from "../../../util/helper";
// import { FormLoader } from "../../fields/formLoader/FormLoader";
// import "./styles.css";
// import ChatHead from "./chatHead/ChatHead";
// import { FinishFormDialog } from "../../fields/finishFormDialog/FinishFormDialog";


// const FormChat = ({
//   isQuesLoading,
//   isFormInitLoading,
//   isSearchLoading,
//   formFinished,
//   respModel,
//   setPage,
// }) => {
//   const [resetChatDialogBox, setResetChatDialogBox] = useState(false);
//   const [finishChatDialogBox, setFinishChatDialogBox] = useState(false);

//   const getFormLoadingStatus = () =>
//     isFormInitLoading || isQuesLoading || isSearchLoading;
//   const toggleFinishDialogBox = useCallback(() => {
//       setFinishChatDialogBox((prevDialogVal) => !prevDialogVal);
//     }, []);
  
//   // useEffect(() => {
//   //   if (formFinished) {
//   //     sendMessage({ resp_msg: "Form Finished", resp_model: respModel });
//   //   }
//   // }, [formFinished, respModel]);

//   const toggleResetDialogBox = useCallback(() => {
//     setResetChatDialogBox((prevClosedDialog) => !prevClosedDialog);
//   }, []);
//   useEffect(() => {
//     if (formFinished) {
//       toggleFinishDialogBox();
//       sendMessage({ resp_msg: "Form Finished", resp_model: respModel });
//     }
//   }, [formFinished, respModel, toggleFinishDialogBox]);


//   return (
    
//     <div className="formChat">
//       <ChatHead />
//       <ChatBody {...{ toggleResetDialogBox, isQuesLoading, isSearchLoading }} />
//       {resetChatDialogBox && <CloseFormDialog {...{ toggleResetDialogBox }} />}
//       {finishChatDialogBox && (
//         <FinishFormDialog {...{ toggleResetDialogBox, setPage }} />
//       )}

//       {getFormLoadingStatus() && <FormLoader />}

//     </div>
//   );
// };

// const mapStateToProps = (state) => ({
//   isQuesLoading: state.formStatus.ques_loading,
//   isSearchLoading: state.formStatus.search_loading,
//   isFormInitLoading: state.formStatus.form_loading,
//   formFinished: state.formStatus.form_finished,
//   respModel: state.formResponses.respModel,
// });

// export default connect(mapStateToProps)(FormChat);



import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useCallback } from "react";
import { ChatBody } from "./chatBody/ChatBody";
import { CloseFormDialog } from "../../fields/closeFormDialog/CloseFormDialog";
import { sendMessage } from "../../../util/helper";
import { FormLoader } from "../../fields/formLoader/FormLoader";
import { ResponseModel } from "../../common/responseModel/ResponseModel";
import ChatHead from "./chatHead/ChatHead";
import "./styles.css";

const FormChat = ({
  isQuesLoading,
  isFormInitLoading,
  isSearchLoading,
  formFinished,
  respModel,
}) => {
  const [resetChatDialogBox, setResetChatDialogBox] = useState(false);
  const [summaryDialogBox, setSummaryDialogBox] = useState(false);

  const getFormLoadingStatus = () =>
    isFormInitLoading || isQuesLoading || isSearchLoading;

  useEffect(() => {
    if (formFinished) {
      sendMessage({ resp_msg: "Form Finished", resp_model: respModel });
    }
    setSummaryDialogBox(formFinished);
  }, [formFinished, respModel]);

  const toggleResetDialogBox = useCallback(() => {
    setResetChatDialogBox((prevClosedDialog) => !prevClosedDialog);
  }, []);

  const toggleSummaryDialogBox = useCallback(() => {
    setSummaryDialogBox((prevClosedDialog) => !prevClosedDialog);
  }, []);

  return (
    <div className="formChat">
      <ChatHead />
      <ChatBody {...{ toggleResetDialogBox, isQuesLoading, isSearchLoading }} />
      {resetChatDialogBox && <CloseFormDialog {...{ toggleResetDialogBox }} />}
      {getFormLoadingStatus() && <FormLoader />}
      {summaryDialogBox && (
        <ResponseModel {...{ respModel, toggleSummaryDialogBox }} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isQuesLoading: state.formStatus.ques_loading,
  isSearchLoading: state.formStatus.search_loading,
  isFormInitLoading: state.formStatus.form_loading,
  formFinished: state.formStatus.form_finished,
  respModel: state.formResponses.resp_model,
});

export default connect(mapStateToProps)(FormChat);