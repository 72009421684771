import green_tick from "../../../assets/images/green_tick.png";
import cancel_icon from "../../../assets/images/close_button.png";
import { capitalizeFirstLetter } from "../../../util/helper";
import "./styles.css";

const ResponseModel = (props) => {
  console.log("THIS IS PROPS", props)
  const getDispVal = (resp_model) => {
    console.log("THIS IS RESP MODEL",resp_model)
    return (
      <>
        <span
          style={{
            fontWeight: "700",
            alignSelf: "center",
            textAlign: "center",
            marginBottom: "1.5%",
            marginTop: "2.5%",
          }}
        >
          AI Recommended Differential Diagnosis:
        </span>
        {resp_model.length === 0 ? (
          <span
            style={{
              marginBottom: "3%",
              alignSelf: "center",
            }}
          >
            The entered set of symptoms are currently not supported by the
            system.
          </span>
        ) : (
          <ol
            style={{
              margin: "0 0 3% 0",
              alignSelf: "center",
            }}
          >
            {resp_model.map((itm, idx) => (
              <li key={idx}>
                {capitalizeFirstLetter(itm.alias)}
              </li>
            ))}
          </ol>
        )}
      </>
    );
  };

  const getSympDisp = (sympArr) => {
    console.log("symptpm", sympArr)
    return (
      <>
        <span
          style={{
            fontWeight: "700",
            alignSelf: "center",
          }}
        >
          Chief Complaints:
        </span>
        {sympArr.map((itm, idx) => (
          <div
            key={itm["Alias"]}
            style={{
              paddingLeft: "20px",
              fontWeight: "700",
            }}
          >
            {itm["Alias"] ? itm["Alias"] : ""}
            <div
              style={{
                paddingLeft: "20px",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {itm["Attributes"]?.map((attrItm, idx) => (
                <div
                  key={attrItm["Tag"] + " " + attrItm["Value"]}
                  style={{
                    whiteSpace: "nowrap",
                    paddingRight: "15px",
                    fontSize: "1rem",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    {attrItm["Tag"]}
                  </span>
                  :{" "}
                  <span
                    style={{
                      fontWeight: "500",
                    }}
                  >
                    {attrItm["Value"]}
                  </span>
                </div>
              ))}
            </div>
            {itm["Attributes"].map((attrItm) =>
              attrItm["Attachments"]?.map((imgItm, idx) => (
                <img
                  key={imgItm}
                  className="filePreview_itm"
                  src={imgItm}
                  alt="preview_file"
                  style={{ marginLeft: "20px" }}
                />
              ))
            )}
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="responseModelDialog">
      <div className="responseModelDialogBackground" />
      <div className="responseModelDialogBox">
        <img
          className="responsemodel_cancel_icon"
          src={cancel_icon}
          alt={"responsemodel_icn"}
          onClick={props.toggleSummaryDialogBox}
        />
        <img
          className="finishFormDialog_icn"
          src={green_tick}
          alt={"finishFormDialog_icn"}
        />
        {getDispVal(props.respModel.Response.differentialDiagnosis)}
        {getSympDisp(props.respModel.Response.symptoms)}
        {/* <div className="cameraWrapDialog_action">
          <span
            className="cameraWrapDialog_action_btn camera_btn_right"
            
          >
            {"Cancel"}
          </span>
        </div> */}
      </div>
    </div>
  );
};

export { ResponseModel };